input[type=text] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
  }

  .submit-button{
    width: 20rem;
    height: 3rem;
  }