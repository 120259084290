.footer{
    margin-top: 2rem;
    width: 100%;
    height: 20rem;
}

.cardStyle{
    background: #e0e0e0;
box-shadow:  9px 9px 36px #cecece,
-9px -9px 36px #f2f2f2;
}
.cardStyle:hover{
    scale: 1.1;
    transition: all 500ms;
}