.herosection {
  background-color: #dfe9f2;
  width: 100%;
}

.text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text-box h2{
  font-size: 3rem;
  font-weight: 700;
}

.text-box p {
    display: block;
   font-size: 1.2rem;
   font-weight: 500;
   text-align: left;
   color: #33459B;
  }
.btn-width{
    margin-top: 2rem;
    width: 10rem;
    height: 4rem;
}

.authorize{
  border: 1px solid black;
  border-radius: 10px;
}