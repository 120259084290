.banner{
    height: 50px;
    background-color: #FF3301;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
}
.icons{
    cursor: pointer;
    color: #fff;
}
